import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { useDynamicLogout } from '@/hooks/useDynamicLogout'
import { useSelectNewToken } from '@/hooks/useSelectNewToken.hook.ts'
import { ListButton } from '@/libs/common'
import { chainsConfig } from '@/libs/configs/chains.config'
import { AppRoute, IconName } from '@/libs/enums'
import { getLastVisitedToken } from '@/libs/helper/lastVisitedToken'
import { TChainConfig } from '@/libs/types/chain.type'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppDispatch, useAppSelector } from '@/store'
import { setCurrentChain } from '@/store/slices/chain.slice'
import { fetchUserBuyTemplates } from '@/store/slices/user.slice.ts'

import styles from './styles.module.scss'

type TProperty = { test?: string }

const SwitchChain: FC<TProperty> = () => {
  const dispatch = useAppDispatch()
  const handleNewTokenSelection = useSelectNewToken()
  const navigate = useCustomNavigate()
  const { setModalProps } = useModal()
  const { t } = useTranslation()

  const { dynamicLogout } = useDynamicLogout()

  const userData = useAppSelector((state) => state.user.userData)

  const onChange = (newChain: TChainConfig) => {
    function changeChain() {
      dispatch(setCurrentChain(newChain))
      const lastVisitedToken = getLastVisitedToken(newChain.id)
      handleNewTokenSelection(lastVisitedToken || newChain.defaultToken, {
        customChain: newChain.description.toLowerCase(),
      })
      dispatch(fetchUserBuyTemplates())
    }

    if (userData) {
      const accounts = userData.accounts ?? []

      const solanaAccount = accounts.find((account) => account.blockchain_id === 8)
      const evmAccount = accounts.find((account) => account.blockchain_id !== 8)

      const hasAccountInNewChain = !!(
        (newChain.isEVM && evmAccount) ||
        (!newChain.isEVM && solanaAccount)
      )

      if (!hasAccountInNewChain) {
        dynamicLogout()
        changeChain()
        navigate({
          path: `/${AppRoute.MODAL}/${AppRoute.LINK_WALLET}`,
        })

        return
      }
    }

    changeChain()
    navigate({
      isDashboard: true,
      newChain: newChain.description.toLowerCase(),
      searchParams: {
        token: newChain.defaultToken,
      },
    })
  }

  useEffect(() => {
    setModalProps({
      title: t('modal.switch_chain'),
    })
  }, [])

  return (
    <div className={styles.switchChainContainer}>
      <div className={styles.chainsList}>
        {chainsConfig.map((chain) => (
          <ListButton
            iconName={(IconName as any)[chain.networkIconName]}
            title={chain.label}
            description={chain.description}
            onClick={() => {
              onChange(chain)
            }}
            key={chain.iconName}
          />
        ))}
      </div>
    </div>
  )
}

export { SwitchChain }
