import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'


import { DynamicEmbeddedWidget, useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { type AxiosError } from 'axios'
import bs58 from 'bs58'

import { attachChainToAccount } from '@/api/user'
import { CustomToast } from '@/components/custom-toast'
import { useCustomNavigate } from '@/hooks/useCustomNavigate'
import { useDynamicLogout } from '@/hooks/useDynamicLogout'
import { AppRoute } from '@/libs/enums'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchUserById } from '@/store/slices/user.slice'
import { getNonceFromMessage } from '@/utils/get-nonce-from-message'

const LinkWallet = () => {
  const navigate = useCustomNavigate()
  const [searchParams] = useSearchParams()

  const dispatch = useAppDispatch()

  const userData = useAppSelector((state) => state.user.userData)
  const authData = useAppSelector((state) => state.auth.authData)
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const { primaryWallet } = useDynamicContext()
  const { dynamicLogout } = useDynamicLogout()

  const [isInProcess, setIsInProcess] = useState(false)

  const handleAttachChain = async ({
    chain,
    user,
    wallet,
    signature,
    nonce,
    messageToSign,
  }: {
    chain: number
    user: NonNullable<typeof userData>
    wallet: NonNullable<typeof primaryWallet>
    signature: string
    nonce: string
    messageToSign: string
  }) => {
    await attachChainToAccount(user.user_id, {
      blockchain: chain,
      message: messageToSign,
      signatory: wallet.address,
      signature,
      nonce,
    })

    await dispatch(fetchUserById(user.user_id)).unwrap()

    navigate({
      path: AppRoute.SETUP_WALLETS,
      isDashboard: !localStorage.code,
      searchParams: localStorage.code
        ? { code: localStorage.code }
        : { token: searchParams.get('token') ?? currentChain.defaultToken },
      replace: false,
    })
  }

  const linkWallet = async ({
    user,
    auth,
    wallet,
  }: {
    user: NonNullable<typeof userData>
    auth: NonNullable<typeof authData>
    wallet: NonNullable<typeof primaryWallet>
  }) => {
    setIsInProcess(true)
    let signature = auth.signedMessage
    try {
      const nonce = getNonceFromMessage(auth.messageToSign)

      await handleAttachChain({
        chain: currentChain.id,
        user,
        wallet,
        signature,
        nonce,
        messageToSign: auth.messageToSign,
      })
    } catch (e) {
      const error = e as AxiosError<{
        id: string
        status: string
        data: {
          code: string
          description: string
          title: string
        }
      }>

      if (!error.response) {
        CustomToast('error', 'Something went wrong')
        return
      }

      const { title, description } = error.response.data.data
      if (title === 'Invalid Signature') {
        signature = bs58.encode(Buffer.from(signature, 'base64'))
        await handleAttachChain({
          chain: currentChain.id,
          user,
          wallet,
          signature,
          nonce: getNonceFromMessage(auth.messageToSign),
          messageToSign: auth.messageToSign,
        })
      } else {
        CustomToast('error', `${title}: ${description}`)
      }
    } finally {
      setIsInProcess(false)
      dynamicLogout()
    }
  }

  useEffect(() => {
    alert(`userData: ${JSON.stringify(userData?.username)}\nauthData: ${JSON.stringify(authData?.signedMessage)}\nprimaryWallet: ${JSON.stringify(primaryWallet?.chain)}\nisInProcess: ${isInProcess}`)
    if (userData && authData && primaryWallet && !isInProcess) {
      linkWallet({
        user: userData,
        auth: authData,
        wallet: primaryWallet,
      })
    }
  }, [userData, authData, primaryWallet, isInProcess])

  return <DynamicEmbeddedWidget />
}

export { LinkWallet }
